import { useContext, useEffect, useState } from "react";
import { Col, Row, Select, Typography } from "antd";
import { AiFillBank } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { organizations } from "../../constants/orgs";
import { sidebarItems } from "../../constants/sidebarItems";
import { OrgProviderContext } from "../../providers/OrgProvider";
import { PathwayProviderContext } from "../../providers/PathwayProvider";

const { Text } = Typography;

const Sidebar = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { orgDispatch } = useContext(OrgProviderContext);
	const { pathwayDispatch } = useContext(PathwayProviderContext);
	const [selectedOrg, setSelectedOrg] = useState("FECP-2024");
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const colorMap: {
		[key: string]: {
			primary: string;
			gradientFrom: string;
			gradientTo: string;
		};
	} = {
		"CLDSCHL-2024": {
			primary: "#1E90FF", // DodgerBlue
			gradientFrom: "#1A73E8", // Slightly darker shade of DodgerBlue
			gradientTo: "#1E90FF", // DodgerBlue
		},
		"FECP-2024": {
			primary: "#032EB7", // Cobalt Blue
			gradientFrom: "#001A88", // Darker shade of Cobalt Blue
			gradientTo: "#032EB7", // Cobalt Blue
		},
		"APPER-2024": {
			primary: "#CF5C77", // Watermelon Pink
			gradientFrom: "#A1445D", // Darker shade of Watermelon Pink
			gradientTo: "#CF5C77", // Watermelon Pink
		},
	};

	const handleChange = (selectedOption: any) => {
		console.log("SELECTED OPTION", selectedOption);
		if (selectedOption) {
			orgDispatch({ type: "SET_CURRENT_ORG", payload: selectedOption });
			pathwayDispatch({
				type: "RESET_PATHWAYS",
				payload: null,
			});
			navigate(`/?orgId=${selectedOption}`);
			setSelectedOrg(selectedOption);
		}
	};

	useEffect(() => {
		const { primary, gradientFrom, gradientTo } = colorMap[selectedOrg];
		document.documentElement.style.setProperty("--primary-color", primary);
		document.documentElement.style.setProperty("--gradient-from", gradientFrom);
		document.documentElement.style.setProperty("--gradient-to", gradientTo);
	}, [selectedOrg]);

	useEffect(() => {
		const current = searchParams?.get("orgId");
		setSelectedOrg(current || "FECP-2024");
		orgDispatch({ type: "SET_CURRENT_ORG", payload: current || "FECP-2024" });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Col className="fixed h-screen w-[250px] bg-primary z-50">
			<Row className="h-[90px] items-center gap-2 bg-primary p-4 drop-shadow-xl">
				<AiFillBank
					size={40}
					className="cursor-pointer text-white"
					onClick={() => navigate(`/?orgId=${selectedOrg}`)}
				/>
				<Text
					className="cursor-pointer text-sm font-semibold uppercase leading-tight text-white"
					onClick={() => navigate(`/?orgId=${selectedOrg}`)}>
					Talentboost<br></br> Admin Console
				</Text>
			</Row>

			<Col className="p-6">
				<Select
					className="mb-4 w-full"
					value={selectedOrg}
					onChange={handleChange}
					options={organizations}
				/>
				<Col className="">
					{sidebarItems.map((item, index) => {
						return (
							<Row
								key={index}
								className={`text-white mb-1 flex h-auto cursor-pointer items-center gap-2 px-4 py-2 ${
									item.path.includes(pathname.split("/")[1]) && pathname !== "/"
										? "bg-gradient-to-r from-[var(--gradient-from)] to-[var(--gradient-to)] font-bold"
										: ""
								} ${
									item.path === "/" && pathname === "/"
										? "bg-gradient-to-r from-[var(--gradient-from)] to-[var(--gradient-to)] font-bold"
										: ""
								}`}
								onClick={() => {
									navigate(`${item.path}?orgId=${selectedOrg}`);
								}}>
								{item.icon}
								<Text className="text-md leading-none text-white">
									{item.title}
								</Text>
							</Row>
						);
					})}
				</Col>
			</Col>
		</Col>
	);
};

export default Sidebar;
