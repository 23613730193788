import "../assets/styles/styles.css";
import { Button, Form, Input, Modal, Row, Select } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { MdDeleteForever } from "react-icons/md";
import DashboardLayout from "../components/layout/DashboardLayout";
import { createAdmin, deleteAdmin, getAllAdmins } from "../queries/admins";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import PageLoader from "../components/loaders/pageLoader";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { showSuccessToast } from "../components/toasts/ToastSuccess";
import { showErrorToast } from "../components/toasts/ToastError";

dayjs.extend(relativeTime);
const { Option } = Select;

export type Admin = {
	name: string;
	email: string;
	createdAt: string;
	verifiedAt: string;
	id: string;
};

type NewAdmin = {
	email: string;
	name: string;
};

export default function Admins() {
	const [admins, setAdmins] = useState<Admin[]>([]);
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [isCreatingAdmin, setIsCreatingAdmin] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [displayData, setDisplayData] = useState<Admin[]>([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [selectedAdmin, setSelectedAdmin] = useState<Admin>();

	const location = useLocation();

	const fetchAllAdmins = async () => {
		setIsLoading(true);
		try {
			const _admins = await getAllAdmins();
			setAdmins(_admins);
		} catch (error) {
			console.error("Error fetching learners:", error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		fetchAllAdmins();
	}, []);

	useEffect(() => {
		setDisplayData(admins);
	}, [location, admins]);

	const handleCancel = () => {
		setIsCreateModalOpen(false);
	};

	const onFinish = async (values: NewAdmin) => {
		setIsCreatingAdmin(true);

		try {
			await createAdmin(values.email, values.name);
			fetchAllAdmins();
			setIsCreateModalOpen(false);
			showSuccessToast({ message: "Admin created successfully!" });
		} catch (error: any) {
			showErrorToast({ message: error.response.data.message });
		}
		setIsCreatingAdmin(false);
	};

	const handleConfirmDelete = async (email: string, id: string) => {
		setIsDeleting(true);
		try {
			const deletedAdmin = await deleteAdmin(email, id);
			if (deletedAdmin) {
				showSuccessToast({ message: "Admin deleted successfully!" });
				fetchAllAdmins();
			}
		} catch (error: any) {
			showErrorToast({ message: error.response.data.message });
		}
		setIsDeleteModalOpen(false);
		setIsDeleting(false);
		fetchAllAdmins();
	};

	const handleNameFilter = (selectedName: string) => {
		if (!selectedName) {
			setDisplayData(admins);
		}

		if (selectedName) {
			setDisplayData(admins.filter((x) => selectedName?.includes(x.name)));
		}
	};

	const AdminRow = (item: Admin, index: number) => {
		const styles = "p-2";

		return (
			<tr
				key={index}
				className="border-b-2 align-top">
				<td className={styles}>{item.name}</td>
				<td className={styles}>{item.email}</td>
				<td className={styles}>{dayjs(item.createdAt).fromNow()}</td>
				<td className={styles}>
					{item.verifiedAt ? "Verified" : "Unverified"}
				</td>
				<td className={`${styles} flex justify-center`}>
					<button
						onClick={() => {
							setIsDeleteModalOpen(true);
							setSelectedAdmin(item);
						}}>
						<MdDeleteForever className="text-red-600 text-2xl" />
					</button>
				</td>
			</tr>
		);
	};

	return (
		<DashboardLayout>
			{isLoading ? (
				<PageLoader />
			) : (
				<main className="flex flex-col">
					<Row className="w-full">
						<Row className="mb-4 w-full justify-between">
							<Button
								className="bg-primary text-white px-4 flex items-center gap-2"
								onClick={() => setIsCreateModalOpen(true)}>
								<FaPlus /> Create Admin
							</Button>
							<Select
								allowClear
								placeholder="Filter by Name"
								onChange={handleNameFilter}
								className="w-1/3">
								{admins.map((admin, index) => {
									return (
										<Option
											value={admin.name}
											key={index}>
											{admin.name}
										</Option>
									);
								})}
							</Select>
						</Row>
						<table className="w-full">
							<thead className="bg-primary text-white">
								<tr className="text-left">
									<th className="min-w-[200px] p-2">Name</th>
									<th className="min-w-[200px] p-2">Email</th>
									<th className="min-w-[100px] p-2">Created At</th>
									<th className="min-w-[100px] p-2">Status</th>
									<th className="min-w-[80px] p-2 text-center">Actions</th>
								</tr>
							</thead>
							<tbody>
								{displayData?.map((item, index) => {
									return AdminRow(item, index);
								})}
							</tbody>
						</table>
					</Row>
					<Modal
						title={`Create Admin`}
						open={isCreateModalOpen}
						onCancel={handleCancel}
						footer={null}>
						<Form
							name="login-form"
							className="justify-center flex flex-col mt-4"
							onFinish={onFinish}>
							<Form.Item
								name="email"
								rules={[
									{ required: true, message: "Email address is required." },
									{
										type: "email",
										message: "Please enter a valid email address.",
									},
								]}>
								<Input
									size="large"
									placeholder="Email"
									className="input-centered-round"
								/>
							</Form.Item>
							<Form.Item
								name="name"
								rules={[{ required: true, message: "Name is required." }]}>
								<Input
									size="large"
									placeholder="Name"
									className="input-centered-round"
								/>
							</Form.Item>

							<div className="flex w-full items-center justify-end ">
								<Button
									size="large"
									className="mr-2"
									onClick={handleCancel}>
									Cancel
								</Button>
								<Button
									size="large"
									htmlType="submit"
									className="bg-primary text-white"
									loading={isCreatingAdmin}>
									{isCreatingAdmin ? "Creating Admin..." : "Create Admin"}
								</Button>
							</div>
						</Form>
					</Modal>
				</main>
			)}
			{selectedAdmin && (
				<Modal
					title={`Delete Admin`}
					open={isDeleteModalOpen}
					onOk={() =>
						handleConfirmDelete(selectedAdmin.email, selectedAdmin.id)
					}
					onCancel={() => setIsDeleteModalOpen(false)}
					okText={isDeleting ? "Deleting..." : "Confirm"}>
					<div>
						<h1>
							Are you sure you want to delete {selectedAdmin.name} as Admin?
						</h1>
					</div>
				</Modal>
			)}
			<ToastContainer />
		</DashboardLayout>
	);
}
