import { createContext, useReducer, ReactNode } from "react";

interface State {
	currentOrg: any; // Define the type of currentOrg according to your data structure
	loading: boolean;
}

interface Action {
	type: string;
	payload?: any; // Define the payload type according to your action types
}

const initialState: State = {
	currentOrg: "FECP-2024",
	loading: true,
};
export const OrgProviderContext = createContext<{
	orgState: State;
	orgDispatch: React.Dispatch<Action>;
}>({ orgState: initialState, orgDispatch: () => null });

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "SET_CURRENT_ORG":
			return { ...state, currentOrg: action.payload, loading: false };
		case "LOADING":
			return { ...state, loading: true };
		default:
			return state;
	}
};

interface OrgProviderProps {
	children: ReactNode;
}

export const OrgProvider = ({ children }: OrgProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<OrgProviderContext.Provider
			value={{ orgState: state, orgDispatch: dispatch }}>
			{children}
		</OrgProviderContext.Provider>
	);
};
