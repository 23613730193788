import axios, { AxiosResponse } from "axios";

export const API_GATEWAY_ENDPOINT: string | undefined =
	process.env.REACT_APP_API_GATEWAY_ENDPOINT;

interface ApiGatewayOptions {
	method: string;
	headers: {
		"Content-Type": string;
		"X-Api-Key"?: string | null;
	};
}

export const fetchAuthApi = async (
	params: Record<string, any>,
	endpoint: string = "auth"
): Promise<AxiosResponse> => {
	const apiGatewayOptions: ApiGatewayOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
	};

	const response: AxiosResponse = await axios.post(
		`${API_GATEWAY_ENDPOINT}/${endpoint}`,
		params,
		apiGatewayOptions
	);
	return response;
};
