import { useContext } from "react";
import { Outlet } from "react-router-dom";
import DashboardLayout from "../components/layout/DashboardLayout";
import { PathwayProviderContext } from "../providers/PathwayProvider";

const Schedules = () => {
	return (
		<DashboardLayout>
			<Outlet></Outlet>
		</DashboardLayout>
	);
};

export default Schedules;
