import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PathwayProviderContext } from "../../providers/PathwayProvider";
import { Course } from "../pathways/Pathways";
import {
	getPathwaysByOrgStatus,
	modifyCourseDetailsByPathway,
	SessionSchedule,
} from "../../queries/pathways";
import { OrgProviderContext } from "../../providers/OrgProvider";
import { Row, DatePicker, DatePickerProps } from "antd";
import { fetchCourseModules } from "../../queries/courses";
import dayjs, { Dayjs } from "dayjs";

// import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import PageLoader from "../loaders/pageLoader";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const ActiveCourse = () => {
	const { orgState } = useContext(OrgProviderContext);
	const { slug, courseId } = useParams();
	const { pathwayState, pathwayDispatch } = useContext(PathwayProviderContext);
	const { activePathway } = pathwayState;
	const [isLoading, setIsLoading] = useState(false);
	const [sessions, setSessions] = useState<any>([]);
	const [currentSchedules, setCurrentSchedules] = useState<SessionSchedule[]>(
		[]
	);

	const navigate = useNavigate();

	console.log(currentSchedules);

	const [activeCourse, setActiveCourse] = useState<Course | undefined>(
		undefined
	);

	const getActivePathway = async () => {
		const _pathway = await getPathwaysByOrgStatus(orgState.currentOrg, slug);
		pathwayDispatch({
			type: "SET_ACTIVE_PATHWAY",
			payload: _pathway[0],
		});
	};

	useEffect(() => {
		if (!activePathway) {
			getActivePathway();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const fetchModules = async () => {
			const _sessions: any = await fetchCourseModules(courseId!);
			const cycleSessions = _sessions?.cycle?.sessions;
			setSessions(cycleSessions);
		};

		if (activePathway) {
			fetchModules();
			setActiveCourse(
				activePathway?.courses?.filter((item) => item.id === courseId)[0]
			);

			const _currentCourseSchedules = activePathway?.schedules?.filter(
				(item) => item.id === courseId
			)[0]?.schedules;

			setCurrentSchedules(_currentCourseSchedules);
		}
	}, [activePathway]);

	const handleSessionTimeChange = (sessionId: string, date: Dayjs) => {
		const awsDateTime = date?.toISOString();

		setCurrentSchedules((prev) => {
			const updatedSchedule = prev.map((session) => {
				if (session.id === sessionId) {
					return { ...session, schedule: awsDateTime };
				}
				return session;
			});
			return updatedSchedule;
		});
	};

	const handleLessonTimeChange = (
		sessionId: string,
		sessionItemId: string,
		date: Dayjs
	) => {
		const awsDateTime = date?.toISOString();

		setCurrentSchedules((prev) => {
			const updatedSchedule = prev.map((session) => {
				if (session.id === sessionId) {
					// Update the sessionItems array with the new schedule for the matching item
					const updatedItems = session.sessionItems.map((item) => {
						if (item.sessionItemId === sessionItemId) {
							return { ...item, schedule: awsDateTime };
						}
						return item;
					});

					return { ...session, sessionItems: updatedItems };
				}
				return session;
			});
			return updatedSchedule;
		});
	};

	const handleSaveSchedules = async () => {
		setIsLoading(true);
		try {
			const orgId = activePathway?.orgId;
			const pathwayId = activePathway?.id;

			if (!orgId || !pathwayId) {
				throw new Error("Missing organization or pathway ID");
			}

			const processedSchedules = currentSchedules.map((item) => {
				return {
					...item,
					sessionItems: item.sessionItems.map((sessionItem) => ({
						...sessionItem,
						schedule: sessionItem.schedule || item.schedule, // Set to parent's schedule if null
					})),
				};
			});

			await modifyCourseDetailsByPathway(
				// currentSchedules,
				processedSchedules,
				orgId,
				pathwayId,
				courseId
			);

			console.log("Schedules saved successfully");
		} catch (error) {
			console.error("Error saving schedules:", error);
			alert("Failed to save schedules. Please try again.");
		} finally {
			setIsLoading(false);
		}
	};

	const getCurrentModuleSchedule = (moduleId: string) => {
		const currentModuleSched = currentSchedules?.filter(
			(item: any) => item.id === moduleId
		)[0].schedule;

		const formatted = dayjs(currentModuleSched).format(
			"dddd DD/MM/YYYY hh:mm A"
		);
		return formatted;
	};

	const getCurrentLessonSchedule = (moduleId: string, lessonId: string) => {
		const currentLessonSched = currentSchedules
			?.filter((item: any) => item.id === moduleId)[0]
			?.sessionItems.filter(
				(les: any) => les?.sessionItemId === lessonId
			)[0]?.schedule;

		const formatted = dayjs(currentLessonSched).format(
			"dddd DD/MM/YYYY hh:mm A"
		);

		return formatted;
	};

	const dateFormat = "dddd DD/MM/YYYY hh:mm A";

	const customFormat: DatePickerProps["format"] = (value) =>
		`${value.format(dateFormat)}`;

	if (!activeCourse) {
		return <PageLoader />;
	}

	return (
		<div>
			<h1 className="text-2xl font-bold mb-4">
				Manage Schedules for {activeCourse?.title}
			</h1>
			{/* <pre>{JSON.stringify(currentSchedules, null, 2)}</pre> */}
			<Row className="w-full mt-2 flex">
				<table className="w-full">
					<thead className="bg-primary text-white">
						<tr className="text-left">
							<th className="min-w-[200px] p-2">Session</th>
							<th className="max-w-[300px] w-[300px] p-2 ">Set Availability</th>
						</tr>
					</thead>
					<tbody>
						{sessions?.map((module: any, index: number) => (
							<React.Fragment key={index}>
								<tr className="border-b-2 align-top">
									<td className="p-2 font-semibold">{module?.title}</td>
									<td className="p-2">
										<fieldset className="w-full">
											<DatePicker
												// className={`w-full ${
												// 	getCurrentModuleSchedule(module.id)
												// 		? "custom-placeholder-active"
												// 		: "custom-placeholder-inactive"
												// }`}
												className="w-full"
												placeholder="Session accessible to learners"
												defaultValue={
													getCurrentModuleSchedule(module.id) === "Invalid Date"
														? ""
														: dayjs(
																getCurrentModuleSchedule(module.id).toString(),
																dateFormat
														  )
												}
												onChange={(date) =>
													handleSessionTimeChange(module.id, date as Dayjs)
												}
												format={customFormat}
												showTime={{ use12Hours: true }}
											/>
										</fieldset>
									</td>
								</tr>
								{module?.sessionItems
									.sort((a: any, b: any) => a.title.localeCompare(b.title))
									.map((lesson: any, lessonIndex: number) => (
										<tr
											key={`${index}-${lessonIndex}`}
											className="border-b align-top">
											<td className="p-2 pl-8">{lesson?.title}</td>
											<td className="p-2">
												<fieldset className="w-full">
													<DatePicker
														// className={`w-full ${
														// 	getCurrentLessonSchedule(module.id, lesson.id)
														// 		? "custom-placeholder-active"
														// 		: "custom-placeholder-inactive"
														// }`}
														className="w-full"
														format="dddd DD/MM/YYYY hh:mm A"
														placeholder={"Same with parent session"}
														defaultValue={
															getCurrentLessonSchedule(module.id, lesson.id) ===
															"Invalid Date"
																? ""
																: dayjs(
																		getCurrentLessonSchedule(
																			module.id,
																			lesson.id
																		).toString(),
																		dateFormat
																  )
														}
														onChange={(date) =>
															handleLessonTimeChange(
																module.id,
																lesson.id,
																date as Dayjs
															)
														}
														showTime={{ use12Hours: true }}
													/>
												</fieldset>
											</td>
										</tr>
									))}
							</React.Fragment>
						))}
					</tbody>
				</table>
				<div className="flex justify-end w-full">
					<button
						className="bg-primary text-white px-4 py-2 rounded mt-4"
						onClick={() => {
							handleSaveSchedules();
						}}>
						{isLoading ? "Saving Schedules..." : "Save Schedules"}
					</button>
				</div>
			</Row>
		</div>
	);
};

export default ActiveCourse;
