import { createContext, useReducer, ReactNode } from "react";
import { Pathway } from "../components/pathways/Pathways";

interface State {
	pathways: Pathway[] | null;
	activePathway: Pathway | null;
	loading: boolean;
}

interface Action {
	type: string;
	payload?: any;
}

const initialState: State = {
	pathways: null,
	activePathway: null,
	loading: true,
};
export const PathwayProviderContext = createContext<{
	pathwayState: State;
	pathwayDispatch: React.Dispatch<Action>;
}>({ pathwayState: initialState, pathwayDispatch: () => null });

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "SET_PATHWAYS":
			return { ...state, pathways: action.payload, loading: false };
		case "SET_ACTIVE_PATHWAY":
			return { ...state, activePathway: action.payload, loading: false };
		case "RESET_PATHWAYS":
			return { ...state, pathways: null, activePathway: null, loading: false };
		case "LOADING":
			return { ...state, loading: true };
		default:
			return state;
	}
};

interface PathwayProviderProps {
	children: ReactNode;
}

export const PathwayProvider = ({ children }: PathwayProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<PathwayProviderContext.Provider
			value={{ pathwayState: state, pathwayDispatch: dispatch }}>
			{children}
		</PathwayProviderContext.Provider>
	);
};
