import { useContext, useEffect, useState } from "react";
import { Row, Typography, Menu, Dropdown } from "antd";
import { organizations } from "../../constants/orgs";
import { OrgProviderContext } from "../../providers/OrgProvider";
import Avatar from "react-avatar";
import { AuthProviderContext } from "../../providers/AuthProvider";
import { LogoutOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const { Text } = Typography;

const Header = () => {
	const { orgState } = useContext(OrgProviderContext);
	const { authState } = useContext(AuthProviderContext);
	const { currentUser } = authState;
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const currentOrg = orgState.currentOrg;
	const [headerTitle, setHeaderTitle] = useState(
		currentOrg
			? organizations.find((option) => option.value === currentOrg)?.label
			: "GCash Fintech Engineer Cadetship Program"
	);
	const selectedOrg = searchParams?.get("orgId");
	useEffect(() => {
		const headers = () => {
			switch (selectedOrg) {
				case "CLDSCHL-2024":
					setHeaderTitle("CloudSchool PH");
					break;
				case "FECP-2024":
					setHeaderTitle("GCash Fintech Engineer Cadetship Program");
					break;
				case "APPER-2024":
					setHeaderTitle("Apper Corporate University");
					break;
				default:
					setHeaderTitle("GCash Fintech Engineer Cadetship Program");
			}
		};

		headers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOrg]);

	const appSignOut = () => {
		localStorage.removeItem("AdminToken");
		window.location.reload();
	};

	const logoutMenu = (
		<Menu onClick={appSignOut}>
			<Menu.Item
				key="logout"
				icon={<LogoutOutlined />}>
				Logout
			</Menu.Item>
		</Menu>
	);

	const handleAvatarClick = () => {
		setDropdownVisible(!dropdownVisible);
	};

	return (
		<Row className="ml-[250px] fixed z-50 flex h-[90px] w-[calc(100vw-250px)] items-center justify-between bg-white px-8 py-4 drop-shadow-xl">
			<Text className="p-2 text-2xl font-semibold leading-none">
				{headerTitle}
			</Text>

			<Dropdown
				overlay={logoutMenu}
				placement="bottomRight"
				open={dropdownVisible}
				onOpenChange={setDropdownVisible}>
				<div onClick={handleAvatarClick}>
					<Avatar
						name={(currentUser as { name?: string })?.name}
						size="40"
						round={true}
						style={{ cursor: "pointer" }}
					/>
				</div>
			</Dropdown>
		</Row>
	);
};

export default Header;
