import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, Col } from "antd";
import { useEffect, useState } from "react";
import { validatePassword } from "../utils/validatePassword";
import AppLoader from "../components/loaders/appLoader";
import { updateAdmin } from "../queries/auth";

// import { showErrorToast } from '../utils/ToastError';
import { ToastContainer } from "react-toastify";
import { showSuccessToast } from "../components/toasts/ToastSuccess";

const layout = {
	labelCol: { span: 0 },
	wrapperCol: { span: 24 },
};

const { Text, Title } = Typography;

type Admin = {
	name: string;
	email: string;
	id: string;
};
const Register = () => {
	const [adminData, setAdminData] = useState<Admin>();
	const [isUpdatingAdmin, setIsUpdatingAdmin] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	useEffect(() => {
		const email = searchParams.get("email");
		// Replace space (either encoded as %20 or an actual space) with +
		const formatEmail = email?.replace(/\s/g, "+");

		setAdminData({
			name: searchParams.get("name") || "",
			email: formatEmail || "",
			id: searchParams.get("id") || "",
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onFinish = async (values: any) => {
		setIsUpdatingAdmin(true);
		const updateAdminResponse = await updateAdmin(
			adminData?.email,
			values.password,
			adminData?.id
		);
		if (updateAdminResponse.id) {
			showSuccessToast({ message: "Password saved. Redirecting to signin." });
			setTimeout(() => {
				navigate("/signin");
			}, 2000);
		}
		setIsUpdatingAdmin(false);
	};
	return (
		<>
			{!adminData ? (
				<AppLoader />
			) : (
				<Col className="h-screen pt-48">
					<Col className="mx-auto w-[450px]">
						<Col className="mb-8 flex flex-col items-center justify-center">
							<Text>
								Hello {adminData?.name}, welcome to Talentboost Platform's
							</Text>
							<Title style={{ marginTop: "0px" }}>Administrator Console</Title>

							<Text className="text-slate-40 ">
								To complete account setup, provide your desired password below:
							</Text>
						</Col>
						<Form
							{...layout}
							name="set-password-form"
							className="mx-auto px-2"
							onFinish={onFinish}>
							<Form.Item
								name="password"
								rules={[{ validator: validatePassword }]}>
								<Input.Password
									size="large"
									placeholder="Password"
									className="input-centered-round"
									id="new-password"
								/>
							</Form.Item>
							<Form.Item
								name="confirmPassword"
								id="confirm-password"
								dependencies={["password"]}
								rules={[
									{ required: true, message: "Please confirm your password" },
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue("password") === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error("The passwords do not match")
											);
										},
									}),
								]}>
								<Input.Password
									size="large"
									placeholder="Confirm Password"
									className="input-centered-round"
								/>
							</Form.Item>
							<Form.Item>
								<Button
									size="large"
									htmlType="submit"
									className="signin mt-4 w-full bg-[#032EB7] text-white"
									loading={isUpdatingAdmin}>
									{isUpdatingAdmin ? "Setting password..." : "Set Password"}
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Col>
			)}
			<ToastContainer />
		</>
	);
};
export default Register;
