import { toast } from "react-toastify";

interface ToastMessage {
	message: string;
}

export const showSuccessToast = ({ message }: ToastMessage) =>
	toast.success(message, {
		position: "top-right",
		autoClose: 2000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: false,
		pauseOnFocusLoss: false,
		draggable: false,
		progress: undefined,
		theme: "light",
	});
