import { Row, Select, Modal, Checkbox, Button } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "../assets/styles/styles.css";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import DashboardLayout from "../components/layout/DashboardLayout";
import { useContext, useEffect, useState } from "react";
import { getAllLearners, updateLearnerPathways } from "../queries/learners";
import { OrgProviderContext } from "../providers/OrgProvider";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import PageLoader from "../components/loaders/pageLoader";
import { useLocation } from "react-router-dom";
import { Pathway } from "../components/pathways/Pathways";
import { getPathwaysByOrgStatus } from "../queries/pathways";

dayjs.extend(relativeTime);

const { Option } = Select;

type Learner = {
	firstName: string;
	lastName: string;
	email: string;
	verifiedAt: string | null;
	createdAt: string;
	enrolledPathways: string[];
};

export default function Learners() {
	const [learners, setLearners] = useState<Learner[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isUpdatingPathway, setIsUpdatingPathway] = useState(false);

	//States for managing a learner's pathways
	const [selectedLearner, setSelectedLearner] = useState<Learner | null>(null);
	const [selectedLearnerPathways, setSelectedLearnerPathways] = useState<
		string[]
	>([]);
	const [holdLearnerPathways, setHoldLearnerPathways] = useState<string[]>([]); //Used as a backup in cases that the admin cancels the updating of learner's pathways
	const [selectedPathway, setSelectedPathway] = useState<string>(""); //Used to display the pathway's title in the confirmation modal
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	//States for managing a learner's pathways

	//States for displaying and filtering data
	const [displayData, setDisplayData] = useState<Learner[]>([]);

	const { orgState } = useContext(OrgProviderContext);
	const location = useLocation();


	// Get the current pathways

	const [currentPathways, setCurrentPathways] = useState<Pathway[]>([]);

	useEffect(() => {
		const fetchPathways = async () => {
			const _pathways = await getPathwaysByOrgStatus(orgState.currentOrg);

			setCurrentPathways(_pathways);
		};

		fetchPathways();
	}, []);

	const fetchAllLearners = async () => {
		setIsLoading(true);
		try {
			const _learners = await getAllLearners(orgState.currentOrg);
			setLearners(_learners);
		} catch (error) {
			console.error("Error fetching learners:", error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		fetchAllLearners();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgState]);

	useEffect(() => {
		setDisplayData(learners);
	}, [location, learners]);

	const handleClose = () => {
		setSelectedLearner(null);
		setSelectedLearnerPathways([]);
		setIsModalOpen(false);
		setIsConfirmModalOpen(false);
	};

	const handleCancel = () => {
		setSelectedLearnerPathways(holdLearnerPathways);
		setIsConfirmModalOpen(false);
	};

	const handleConfirm = async () => {
		if (!selectedLearner?.email) {
			console.error("Selected learner or learner's email is missing.");
			return;
		}
		try {
			setIsUpdatingPathway(true);
			await updateLearnerPathways(
				orgState.currentOrg,
				selectedLearner.email,
				selectedLearnerPathways
			);
			setIsUpdatingPathway(false);
			setIsConfirmModalOpen(false);
		} catch (error) {
			console.error("Error updating learner pathways:", error);
		}

		setIsModalOpen(false);
		fetchAllLearners();
	};

	const displayTitle = (pw: string) => {
		const current = currentPathways.filter((item) => item.id === pw);
		return current[0]?.title;
	};

	const handleManagePathways = (item: Learner) => {
		setSelectedLearner(item);
		setSelectedLearnerPathways(item.enrolledPathways);
		setHoldLearnerPathways(item.enrolledPathways);
		setIsModalOpen(true);
	};

	const onChange = (e: CheckboxChangeEvent, id: string) => {
		if (e.target.checked) {
			setSelectedLearnerPathways((prev) => [...prev, id]);
		} else {
			setSelectedLearnerPathways((prev) =>
				prev.filter((pathwayId) => pathwayId !== id)
			);
		}
		setSelectedPathway(id);
		setIsConfirmModalOpen(true);
	};

	const confirmModalDisplayText = (id: string) => {
		if (holdLearnerPathways.includes(id)) {
			return `Are you sure you want to remove ${selectedLearner?.firstName} ${
				selectedLearner?.lastName
			} from ${displayTitle(selectedPathway)}?`;
		} else {
			return `Are you sure you want to enroll ${selectedLearner?.firstName} ${
				selectedLearner?.lastName
			} to ${displayTitle(selectedPathway)}?`;
		}
	};

	const handleNameFilter = (selectedName: string) => {
		if (!selectedName) {
			setDisplayData(learners);
		}

		if (selectedName) {
			setDisplayData(
				learners.filter((x) =>
					selectedName?.includes(`${x.firstName} ${x.lastName}`)
				)
			);
		}
	};

	const handlePathwayFilter = (selectedPathway: string) => {
		if (!selectedPathway) {
			setDisplayData(learners);
		}

		if (selectedPathway) {
			setDisplayData(
				learners.filter((x) => x.enrolledPathways.includes(selectedPathway))
			);
		}
	};

	const LearnerRow = (item: Learner, index: number) => {
		const styles = "p-2";

		return (
			<tr
				key={index}
				className="border-b-2 align-top">
				<td className={styles}>
					{item.firstName} {item.lastName}
				</td>
				<td className={styles}>{item.email}</td>
				<td className={styles}>
					{item.verifiedAt ? "Verified" : "Unverified"}
				</td>
				<td className={styles}>{dayjs(item.createdAt).fromNow()}</td>
				<td className={`${styles} pr-6`}>
					<div className="flex flex-row items-start justify-between">
						<div>
							{item.enrolledPathways.map((pathwayId, index) => (
								<div key={index}>{displayTitle(pathwayId)}</div>
							))}
						</div>
						<button
							className="pt-1"
							onClick={() => handleManagePathways(item)}>
							<FaEdit />
						</button>
					</div>
				</td>
				<td className={`${styles} flex justify-center`}>
					<button onClick={() => alert("Delete user function to follow!")}>
						<MdDeleteForever className="text-red-600 text-2xl" />
					</button>
				</td>
			</tr>
		);
	};

	const NoDataToDisplay = () => {
		const styles = "p-4";

		return (
			<tr className="border-b-2 align-top text-center">
				<td
					className={styles}
					colSpan={6}>
					No data to display
				</td>
			</tr>
		);
	};

	return (
		<DashboardLayout>
			{isLoading ? (
				<PageLoader />
			) : (
				<main className="flex flex-col ">
					<Row className="w-full">
						<Row className="mb-4 w-full">
							<Select
								allowClear
								placeholder="Filter by Name"
								onChange={handleNameFilter}
								className="multi-filter w-1/3 pr-4">
								{learners.map((learner, index) => {
									return (
										<Option
											value={`${learner.firstName} ${learner.lastName}`}
											key={index}>
											{`${learner.firstName} ${learner.lastName}`}
										</Option>
									);
								})}
							</Select>
							<Select
								allowClear
								placeholder="Filter by Pathway"
								onChange={handlePathwayFilter}
								className="multi-filter w-1/3 pr-4">
								{currentPathways.map((pw, index) => {
									return (
										<Option
											value={pw.id}
											key={index}>
											{pw.title}
										</Option>
									);
								})}
							</Select>
						</Row>
						<table className="w-full">
							<thead className="bg-primary text-white">
								<tr className="text-left">
									<th className="min-w-[160px] p-2">Name</th>
									<th className="min-w-[160px] p-2">Email</th>
									<th className="min-w-[80px] p-2">Status</th>
									<th className="min-w-[100px] p-2">Created At</th>
									<th className="min-w-[240px] p-2">Enrolled Pathways</th>
									<th className="min-w-[80px] p-2 text-center">Actions</th>
								</tr>
							</thead>
							<tbody>
								{displayData.length !== 0 ? (
									displayData.map((item, index) => {
										return LearnerRow(item, index);
									})
								) : (
									<NoDataToDisplay />
								)}
							</tbody>
						</table>
					</Row>
					<Modal
						title={`Manage Pathways for ${selectedLearner?.firstName} ${selectedLearner?.lastName}`}
						open={isModalOpen}
						onCancel={handleClose}
						centered
						footer={[
							<Button
								key="close"
								type="primary"
								onClick={handleClose}>
								Close
							</Button>,
						]}>
						<div className={`flex flex-col `}>
							{currentPathways.map((item, index) => (
								<Checkbox
									onChange={(e) => onChange(e, item.id)}
									key={index}
									checked={selectedLearnerPathways.includes(item.id)}>
									{item.title}
								</Checkbox>
							))}
						</div>
					</Modal>
					<Modal
						title={`Confirm Changes`}
						open={isConfirmModalOpen}
						onOk={handleConfirm}
						onCancel={handleCancel}
						centered
						okText={isUpdatingPathway ? "Updating..." : "Confirm"}>
						<div className={`flex flex-col h-auto `}>
							{confirmModalDisplayText(selectedPathway)}
						</div>
					</Modal>
				</main>
			)}
		</DashboardLayout>
	);
}
