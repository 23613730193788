import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { OrgProvider } from "./providers/OrgProvider";
import Dashboard from "./pages/Dashboard";
import Learners from "./pages/Learners";
import Admins from "./pages/Admins";
import Announcements from "./pages/Announcements";
import Register from "./pages/Register";
import { AuthProvider } from "./providers/AuthProvider";
import ProtectedRoute from "./components/auth/protectedRoute";
import UnauthenticatedRouteRedirection from "./components/auth/unauthenticatedRoute";
import Signin from "./pages/Signin";
import "react-toastify/dist/ReactToastify.css";

//Pathways
import Pathways from "./pages/Pathways";
import PathwaysMain from "./components/pathways/Pathways";
import PathwaysCourses from "./components/pathways/Courses";
import PathwaysActiveCourse from "./components/pathways/ActiveCourse";

//Schedules
import Schedules from "./pages/Schedules";
import SchedulesMain from "./components/schedules/Schedules";
import SchedulesCourses from "./components/schedules/Courses";
import SchedulesActiveCourse from "./components/schedules/ActiveCourse";

import { PathwayProvider } from "./providers/PathwayProvider";

const unauthenticatedRoutes = [
	{ path: "/signin", component: Signin },
	{ path: "/register", component: Register },
];

const protectedRoutes = [
	{ path: "/", component: Dashboard },
	{ path: "/learners", component: Learners },
	{ path: "/admins", component: Admins },
	// { path: "/schedules", component: Schedules },
	{ path: "/announcements", component: Announcements },
];

function App() {
	return (
		<Router>
			<AuthProvider>
				<OrgProvider>
					<PathwayProvider>
						<Routes>
							{unauthenticatedRoutes.map(
								({ path, component: Component }, index) => (
									<Route
										key={index}
										path={path}
										element={
											<UnauthenticatedRouteRedirection>
												<Component />
											</UnauthenticatedRouteRedirection>
										}
									/>
								)
							)}
							{protectedRoutes.map(({ path, component: Component }, index) => (
								<Route
									key={index}
									path={path}
									element={
										<ProtectedRoute>
											<Component />
										</ProtectedRoute>
									}
								/>
							))}

							{/* SCHEDULES */}
							<Route
								path="/schedules"
								element={
									<ProtectedRoute>
										<Schedules />
									</ProtectedRoute>
								}>
								<Route
									index
									element={<SchedulesMain />}
								/>
								<Route
									path="manage-schedules/:slug"
									element={<SchedulesCourses />}
								/>
								<Route
									path="manage-schedules/:slug/course/:courseId"
									element={<SchedulesActiveCourse />}
								/>
							</Route>

							{/* PATHWAYS */}
							<Route
								path="/pathways"
								element={
									<ProtectedRoute>
										<Pathways />
									</ProtectedRoute>
								}>
								<Route
									index
									element={<PathwaysMain />}
								/>
								<Route
									path="manage-courses/:slug"
									element={<PathwaysCourses />}
								/>
								<Route
									path="manage-courses/:slug/course/:courseId"
									element={<PathwaysActiveCourse />}
								/>
							</Route>
						</Routes>
					</PathwayProvider>
				</OrgProvider>
			</AuthProvider>
		</Router>
	);
}

export default App;
