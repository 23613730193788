import { Outlet } from "react-router-dom";
import DashboardLayout from "../components/layout/DashboardLayout";

const Pathways = () => {
	return (
		<DashboardLayout>
			<Outlet></Outlet>
		</DashboardLayout>
	);
};

export default Pathways;
