import { Spin, Layout } from "antd";
import Background from "../layout/Background";

const { Content } = Layout;

const AppLoader = () => {
	return (
		<>
			<div className="absolute top-0 z-50">
				<div className="relative">
					<Background />
					<Content className="relative flex min-h-screen w-screen flex-col items-center justify-center">
						<Spin size="large" />
					</Content>
				</div>
			</div>
		</>
	);
};

export default AppLoader;
