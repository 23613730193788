import { useContext, useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { AuthProviderContext } from "../../providers/AuthProvider";

type ProtectedRouteProps = {
	children: ReactNode;
};

const UnauthenticatedRouteRedirection = ({
	children,
}: ProtectedRouteProps): JSX.Element | null => {
	const { authState } = useContext(AuthProviderContext);
	const navigate = useNavigate();

	const adminToken = localStorage.getItem("AdminToken");

	useEffect(() => {
		if (adminToken) {
			navigate("/");
		}
	}, [adminToken, navigate]);

	if (authState?.isAuthenticated) {
		return <div></div>;
	}

	return <div>{children}</div>;
};

export default UnauthenticatedRouteRedirection;
