import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PathwayProviderContext } from "../../providers/PathwayProvider";
import { Course } from "./Pathways";
import {
	getPathwaysByOrgStatus,
	modifyCourseDetailsByPathway,
} from "../../queries/pathways";
import { OrgProviderContext } from "../../providers/OrgProvider";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PageLoader from "../loaders/pageLoader";

const ActiveCourse = () => {
	const { orgState } = useContext(OrgProviderContext);
	const { slug, courseId } = useParams();
	const { pathwayState, pathwayDispatch } = useContext(PathwayProviderContext);
	const { activePathway } = pathwayState;
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	const [activeCourse, setActiveCourse] = useState<Course | undefined>(
		undefined
	);
	const [formValues, setFormValues] = useState<Course>({
		coverPhotoUrl: "",
		deps: [],
		id: "",
		title: "",
		description: "",
	});

	const getActivePathway = async () => {
		const _pathway = await getPathwaysByOrgStatus(orgState.currentOrg, slug);
		pathwayDispatch({
			type: "SET_ACTIVE_PATHWAY",
			payload: _pathway[0],
		});
	};

	useEffect(() => {
		if (!activePathway) {
			getActivePathway();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const course = activePathway?.courses?.find((item) => item.id === courseId);
		if (course) {
			setActiveCourse(course);
			setFormValues(course);
		}
	}, [activePathway, courseId, navigate, slug]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleDepsChange = (
		e: React.ChangeEvent<HTMLSelectElement>,
		index: number
	) => {
		const { value } = e.target;
		const newDeps = [...formValues.deps];
		newDeps[index] = value;
		setFormValues((prevValues) => ({
			...prevValues,
			deps: newDeps,
		}));
	};

	const addDep = () => {
		setFormValues((prevValues) => ({
			...prevValues,
			deps: [...prevValues.deps, ""],
		}));
	};

	const removeDep = (index: number) => {
		const newDeps = formValues.deps.filter((_, i) => i !== index);
		setFormValues((prevValues) => ({
			...prevValues,
			deps: newDeps,
		}));
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsLoading(true);
		const orgId = activePathway?.orgId;
		const pathwayId = activePathway?.id;
		const modifiedDeps = formValues.deps.filter((dep) => dep !== "");

		try {
			await modifyCourseDetailsByPathway(
				undefined,
				orgId!,
				pathwayId!,
				formValues.id,
				formValues.title,
				formValues.description,
				formValues.coverPhotoUrl,
				modifiedDeps
			);
			getActivePathway();
			// Navigate back after successful modification
			navigate(
				`/pathways/manage-courses/${pathwayId}?orgId=${orgState.currentOrg}`
			);
		} catch (error) {
			console.error("Error modifying course details:", error);
		}
		setIsLoading(false);
	};

	if (!activeCourse) {
		return <PageLoader />;
	}

	return (
		<div>
			<h1 className="text-2xl font-bold mb-4">Edit Course Details</h1>
			<form
				onSubmit={handleSubmit}
				className="space-y-4">
				<div className="flex flex-col">
					<label className="mb-2 font-medium">Title:</label>
					<input
						type="text"
						name="title"
						value={formValues.title}
						onChange={handleChange}
						className="border border-gray-300 rounded p-2"
					/>
				</div>
				<div className="flex flex-col">
					<label className="mb-2 font-medium">ID:</label>
					<input
						type="text"
						name="id"
						value={formValues.id}
						disabled
						className="border border-gray-300 rounded p-2 bg-gray-100 cursor-not-allowed"
					/>
				</div>
				<div className="flex flex-col">
					<label className="mb-2 font-medium">Course Code:</label>
					<input
						type="text"
						name="description"
						value={formValues.description}
						onChange={handleChange}
						className="border border-gray-300 rounded p-2"
					/>
				</div>
				<div className="flex flex-col">
					<label className="mb-2 font-medium">Dependencies:</label>
					{formValues.deps.length === 0 && (
						<p className="border border-gray-300 rounded p-2 bg-gray-100 cursor-not-allowed mb-4">
							This course currently has no dependencies
						</p>
					)}
					{formValues.deps.map((dep, index) => (
						<div
							key={index}
							className="flex items-center mb-2">
							<select
								value={dep}
								onChange={(e) => {
									console.log(e.target.value);
									handleDepsChange(e, index);
								}}
								className="border border-gray-300 rounded p-2 flex-grow">
								<option
									value=""
									disabled>
									Select a dependency
								</option>
								{activePathway?.courses?.map((course) => (
									<option
										key={course.id}
										value={course.id}>
										{`${course.description}: ${course.title}`}
									</option>
								))}
							</select>
							<button
								type="button"
								onClick={() => removeDep(index)}
								className="ml-2 bg-red-500 text-white px-4 py-2 rounded">
								Remove
							</button>
						</div>
					))}
					<button
						type="button"
						onClick={addDep}
						className="bg-blue-500 text-white px-4 py-2 rounded">
						Add Dependency
					</button>
				</div>
				<div className="flex flex-col">
					<label className="mb-2 font-medium">Cover Photo URL:</label>
					<input
						type="text"
						name="coverPhotoUrl"
						value={formValues.coverPhotoUrl}
						onChange={handleChange}
						className="border border-gray-300 rounded p-2"
					/>
					<h4 className="mt-4 font-medium">Cover Photo Preview:</h4>
					{formValues.coverPhotoUrl && (
						<img
							src={formValues.coverPhotoUrl}
							alt="Cover Photo"
							className="mt-2 max-h-60 rounded shadow-md w-[400px]"
						/>
					)}
				</div>
				<button
					type="submit"
					className="bg-primary text-white px-4 py-2 rounded">
					{isLoading ? (
						<>
							Saving Changes...
							<Spin
								indicator={<LoadingOutlined spin />}
								size="small"
								style={{ color: "white", marginLeft: "8px" }}
							/>
						</>
					) : (
						"Save Changes"
					)}
				</button>
			</form>
		</div>
	);
};

export default ActiveCourse;
