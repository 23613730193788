import { ReactNode } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Col, Layout } from "antd";

interface DashboardLayoutProps {
	children: ReactNode;
}

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
	return (
		<Layout className="manager-console flex w-screen">
			<Sidebar />
			<Col className="w-full">
				<Header />
				<Col className="mt-[90px] p-10 min-h-[calc(100vh-90px)] ml-[250px] w-[calc(100vw-250px)]">
					{children}
				</Col>
			</Col>
		</Layout>
	);
};

export default DashboardLayout;
