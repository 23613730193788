import { useContext, useEffect } from "react";
import { getPathwaysByOrgStatus } from "../../queries/pathways";
import { OrgProviderContext } from "../../providers/OrgProvider";
import { useNavigate, useParams } from "react-router-dom";
import { Row } from "antd";
import { PathwayProviderContext } from "../../providers/PathwayProvider";
import { Course } from "../pathways/Pathways";

const Courses = () => {
	const { orgState } = useContext(OrgProviderContext);
	const { pathwayState, pathwayDispatch } = useContext(PathwayProviderContext);
	const { activePathway } = pathwayState;
	const { slug } = useParams();
	const navigate = useNavigate();

	console.log(pathwayState);
	const getActivePathway = async () => {
		const _pathway = await getPathwaysByOrgStatus(orgState.currentOrg, slug);
		pathwayDispatch({
			type: "SET_ACTIVE_PATHWAY",
			payload: _pathway[0],
		});
	};

	useEffect(() => {
		if (!activePathway) {
			getActivePathway();
		}

		window.scrollTo(0, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const CourseRow = (item: Course, index: number) => {
		const styles = "p-2 pb-6";

		return (
			<tr
				key={index}
				className="border-b-2 align-top">
				<td className={styles}>{item.description}</td>
				<td className={styles}>{item.title}</td>
				<td className="flex items-center justify-center pt-2 gap-2">
					<button
						className="pt-1 text-primary font-semibold"
						onClick={() => {
							navigate(
								`/schedules/manage-schedules/${activePathway?.id}/course/${item.id}?orgId=${orgState.currentOrg}`
							);
						}}>
						Manage Schedules
					</button>
				</td>
			</tr>
		);
	};

	return (
		<div>
			<div className="flex justify-between">
				<h1 className="text-2xl mb-1 font-bold">
					{activePathway?.title} Courses
				</h1>
			</div>

			<Row className="w-full mt-2">
				<table className="w-full">
					<thead className="bg-primary text-white">
						<tr className="text-left">
							<th className="min-w-[120px] p-2">Course Code</th>
							<th className="min-w-[200px] p-2">Title</th>
							<th className="min-w-[100px] p-2 text-center">Actions</th>
						</tr>
					</thead>
					<tbody>
						{activePathway?.courses
							?.slice() // Create a shallow copy of the array to avoid mutating the original array
							.sort((a, b) => a.description.localeCompare(b.description)) // Sort alphabetically by description
							.map((item, index) => {
								return CourseRow(item, index);
							})}
					</tbody>
				</table>
			</Row>
		</div>
	);
};

export default Courses;
