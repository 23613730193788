import { MdDashboard, MdInsertChart, MdBarChart } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { BiSolidMegaphone } from "react-icons/bi";
import { FaRoad } from "react-icons/fa";

export const sidebarItems = [
	{
		title: "Dashboard",
		path: "/",
		icon: <MdDashboard size={24} />,
	},
	{
		title: "Learners",
		path: "/learners",
		icon: <MdInsertChart size={24} />,
	},
	{
		title: "Admins",
		path: "/admins",
		icon: <MdBarChart size={24} />,
	},
	{
		title: "Schedules",
		path: "/schedules",
		icon: <FaCalendarAlt size={20} />,
	},
	{
		title: "Announcements",
		path: "/announcements",
		icon: <BiSolidMegaphone size={22} />,
	},
	{
		title: "Pathways",
		path: "/pathways",
		icon: <FaRoad size={22} />,
	},
];
