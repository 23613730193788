import { fetchAuthApi } from "./api/authApi";

export const loginAdmin = async (values: any) => {
	const loginAdminMutation = `
    mutation loginAdmin($input: LoginAdminInput!) {
        loginAdmin(input: $input) {
            id
            name
            email
            token
        }
    }`;

	const params = {
		query: loginAdminMutation,
		variables: { input: values },
	};

	const loginResponse = await fetchAuthApi(params);
	return loginResponse;
};

export const updateAdmin = async (
	email: string | undefined,
	password: string,
	id: string | undefined
) => {
	const updateAdminMutation = `
    mutation updateAdmin($input: UpdateAdminInput!) {
        updateAdmin(input: $input) {
            id
            name
            email
            createdAt
            updatedAt
            verifiedAt
        }
    }`;

	const input = {
		email: email,
		password: password,
		id: id,
	};

	const params = {
		query: updateAdminMutation,
		variables: { input },
	};

	const response = await fetchAuthApi(params);

	const admins = response.data.updateAdmin;
	return admins;
};
