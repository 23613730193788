import { useContext, useEffect, useState } from "react";
import { OrgProviderContext } from "../../providers/OrgProvider";
import {
	duplicatePathway,
	getPathwaysByOrgStatus,
} from "../../queries/pathways";
import { Form, Modal, Row, Tooltip, Input, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { PathwayProviderContext } from "../../providers/PathwayProvider";
import PageLoader from "../loaders/pageLoader";
import { FaEdit, FaCopy } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";

export interface Course {
	description: string;
	id: string;
	percent?: number;
	title: string;
	deps: string[];
	coverPhotoUrl: string;
	orgId?: string;
	pathwayId?: string;
}
export interface Pathway {
	id: string;
	title: string;
	description: string;
	updatedAt: string;
	courses?: Course[];
	orgId?: string;
	schedules?: any[];
}

const Pathways = () => {
	const { orgState } = useContext(OrgProviderContext);
	const { pathwayState, pathwayDispatch } = useContext(PathwayProviderContext);
	const { pathways, activePathway } = pathwayState;
	const navigate = useNavigate();

	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [newPathway, setNewPathway] = useState({ title: "", description: "" });
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const getPathways = async () => {
			pathwayDispatch({
				type: "RESET_PATHWAYS",
				payload: null,
			});

			const _pathways = await getPathwaysByOrgStatus(orgState.currentOrg);
			pathwayDispatch({
				type: "SET_PATHWAYS",
				payload: _pathways,
			});
		};

		getPathways();
	}, [orgState, pathwayDispatch]);

	const setActivePathway = (id: string) => {
		const selectedPathway = pathways?.filter((pathway) => pathway.id === id)[0];
		pathwayDispatch({
			type: "SET_ACTIVE_PATHWAY",
			payload: selectedPathway,
		});
	};

	// Set active pathway and open modal for duplication
	const openDuplicateModal = (id: string) => {
		const selectedPathway = pathways?.find((pathway) => pathway.id === id);
		pathwayDispatch({
			type: "SET_ACTIVE_PATHWAY",
			payload: selectedPathway,
		});
		setNewPathway({
			title: selectedPathway?.title || "",
			description: selectedPathway?.description || "",
		});
		setCreateModalOpen(true);
	};

	// Handle form changes
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setNewPathway({ ...newPathway, [name]: value });
	};

	const onFinish = async () => {
		setIsLoading(true);

		if (activePathway) {
			await duplicatePathway(
				orgState.currentOrg,
				activePathway?.id,
				newPathway.title,
				newPathway.description
			);
		}
		// console.log("VALUES", params);

		const _pathways = await getPathwaysByOrgStatus(orgState.currentOrg);
		pathwayDispatch({
			type: "SET_PATHWAYS",
			payload: _pathways,
		});

		setIsLoading(false);
		setCreateModalOpen(false);
	};

	const submitDuplicateModalDisabled =
		activePathway?.title === newPathway.title ||
		activePathway?.description === newPathway.description;

	const PathwayRow = (item: Pathway, index: number) => {
		const styles = "p-2";

		return (
			<tr
				key={index}
				className="border-b-2 align-top h-full">
				<td className={styles}>{item.title}</td>
				<td className={styles}>{item.id}</td>
				<td className={styles}>{item.description}</td>
				<td className="h-full">
					<div className="flex h-full items-center justify-center">
						<Tooltip title="Manage Pathway's Courses">
							<button
								onClick={() => {
									setActivePathway(item.id);
									navigate(
										`/pathways/manage-courses/${item.id}?orgId=${orgState.currentOrg}`
									);
								}}
								className={styles}>
								<FaEdit />
							</button>
						</Tooltip>
						<Tooltip title="Duplicate Pathway">
							<button
								onClick={() => openDuplicateModal(item.id)}
								className={styles}>
								<FaCopy />
							</button>
						</Tooltip>
					</div>
				</td>
			</tr>
		);
	};

	if (!pathways) return <PageLoader />;
	return (
		<main className="flex flex-col">
			<Row className="w-full">
				<table className="w-full">
					<thead className="bg-primary text-white">
						<tr className="text-left">
							<th className="min-w-[200px] p-2">Title</th>
							<th className="min-w-[200px] p-2">ID</th>
							<th className="min-w-[200px] p-2">Description</th>
							<th className="min-w-[80px] p-2 text-center">Actions</th>
						</tr>
					</thead>
					<tbody>
						{pathways?.map((item, index) => PathwayRow(item, index))}
					</tbody>
				</table>
			</Row>

			{/* DUPLICATE PATHWAY MODAL */}
			<Modal
				title={`Duplicate Pathway: ${activePathway?.title}`}
				centered
				open={createModalOpen}
				onOk={onFinish} // Trigger form submission when "OK" button is clicked
				onCancel={() => setCreateModalOpen(false)}
				okButtonProps={{
					disabled:
						!newPathway.title ||
						!newPathway.description ||
						submitDuplicateModalDisabled,
				}}
				okText={
					isLoading ? (
						<>
							Duplicating Pathway...
							<Spin
								indicator={<LoadingOutlined spin />}
								size="small"
								style={{ color: "white", marginLeft: "8px" }}
							/>
						</>
					) : (
						"Duplicate Pathway"
					)
				}
				cancelText="Cancel">
				<div className="space-y-4 p-2">
					<Form
						className="space-y-4"
						initialValues={{
							title: newPathway.title,
							description: newPathway.description,
						}}
						onValuesChange={handleChange}>
						<div className="flex flex-col">
							<label className="mb-2 font-medium">New Title:</label>
							<Input
								type="text"
								name="title"
								value={newPathway.title}
								onChange={handleChange}
								required
							/>
							{activePathway && (
								<p
									className={`text-xs  pl-3 mt-1 text-red-600 ${
										activePathway.title === newPathway.title ? "" : "hidden"
									}`}>
									New pathway's title must not be the same with the original
									pathway.
								</p>
							)}
						</div>
						<div className="flex flex-col">
							<label className="mb-2 font-medium">New Description:</label>
							<Input
								type="text"
								name="description"
								value={newPathway.description}
								onChange={handleChange}
								required
							/>
							{activePathway && (
								<p
									className={`text-xs  pl-3 mt-1 text-red-600 ${
										activePathway.description === newPathway.description
											? ""
											: "hidden"
									}`}>
									New pathway's description must not be the same with the
									original pathway.
								</p>
							)}
						</div>
					</Form>
				</div>
			</Modal>
		</main>
	);
};

export default Pathways;
