import { useContext, useEffect } from "react";
import { OrgProviderContext } from "../../providers/OrgProvider";
import { getPathwaysByOrgStatus } from "../../queries/pathways";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";
import { PathwayProviderContext } from "../../providers/PathwayProvider";
import PageLoader from "../loaders/pageLoader";
import { Pathway } from "../pathways/Pathways";

const Schedules = () => {
	const { orgState } = useContext(OrgProviderContext);
	const { pathwayState, pathwayDispatch } = useContext(PathwayProviderContext);
	const { pathways } = pathwayState;
	const navigate = useNavigate();
	console.log(orgState);
	useEffect(() => {
		const getPathways = async () => {
			pathwayDispatch({
				type: "RESET_PATHWAYS",
				payload: null,
			});

			const _pathways = await getPathwaysByOrgStatus(orgState.currentOrg);
			pathwayDispatch({
				type: "SET_PATHWAYS",
				payload: _pathways,
			});
		};

		getPathways();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgState]);

	const setActivePathway = (id: string) => {
		const selectedPathway = pathways?.filter((pathway) => pathway.id === id)[0];
		pathwayDispatch({
			type: "SET_ACTIVE_PATHWAY",
			payload: selectedPathway,
		});
	};

	const PathwayRow = (item: Pathway, index: number) => {
		const styles = "p-2";

		return (
			<tr
				key={index}
				className="border-b-2 align-top">
				<td className={styles}>{item.title}</td>
				<td className={styles}>{item.id}</td>
				<td className={styles}>{item.description}</td>

				<td
					onClick={() => {
						setActivePathway(item.id);
						navigate(
							`/schedules/manage-schedules/${item.id}?orgId=${orgState.currentOrg}`
						);
					}}
					className={`${styles} flex justify-center text-center cursor-pointer text-primary font-bold`}>
					Manage Schedules
				</td>
			</tr>
		);
	};

	if (!pathways) return <PageLoader />;
	return (
		<main className="flex flex-col">
			<Row className="w-full">
				<table className="w-full">
					<thead className="bg-primary text-white">
						<tr className="text-left">
							<th className="min-w-[200px] p-2">Title</th>
							<th className="min-w-[200px] p-2">ID</th>
							<th className="min-w-[200px] p-2">Description</th>
							<th className="min-w-[80px] p-2 text-center">Actions</th>
						</tr>
					</thead>
					<tbody>
						{pathways?.map((item, index) => {
							return PathwayRow(item, index);
						})}
					</tbody>
				</table>
			</Row>
		</main>
	);
};

export default Schedules;
