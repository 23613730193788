import { fetchAdminApi } from "./api/adminApi";

export const fetchCourseModules = async (id: string) => {
	const getCourseModulesQuery = `
    query cycle($id: ID!) {
        cycle(id: $id) {
            id
            title
            description
            sessions {
                id
                title
                description
                sessionItems {
                    ... on Lab {
                        __typename
                        id
                        title
                        contentType
                        videoContent
                        urlContent
                        exerFileContent
                        description
                        createdAt
                    }
                    ...on Quiz {
                        __typename
                        id
                        title
                        description
                        createdAt
                    }
                    ...on Assignment {
                        __typename
                        id
                        title
                        description
                        createdAt
                    }
                }
            }
        }
    }
`;

	const params = {
		query: getCourseModulesQuery,
		variables: { id: id, isAdmin: true },
	};

	const response = await fetchAdminApi(params, "springboard");
	return response?.data;
};
