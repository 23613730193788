import { useContext, useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { AuthProviderContext } from "../../providers/AuthProvider";
import AppLoader from "../loaders/appLoader";

type ProtectedRouteProps = {
	children: ReactNode;
};

const ProtectedRoute = ({
	children,
}: ProtectedRouteProps): JSX.Element | null => {
	const { authState } = useContext(AuthProviderContext);
	const navigate = useNavigate();

	const adminToken = localStorage.getItem("AdminToken");

	useEffect(() => {
		if (!adminToken) {
			navigate("/signin");
		}
	}, [adminToken, navigate]);

	if (!authState?.isAuthenticated) {
		return <AppLoader />;
	}

	return <div>{children}</div>;
};

export default ProtectedRoute;
