import axios, { AxiosResponse } from "axios";

export const API_GATEWAY_ENDPOINT: string | undefined =
	process.env.REACT_APP_API_GATEWAY_ENDPOINT;

interface ApiGatewayOptions {
	method: string;
	headers: {
		"Content-Type": string;
		"X-Api-Key"?: string | null;
	};
}

export const fetchAdminApi = async (
	params: Record<string, any>,
	endpoint = "admin"
): Promise<AxiosResponse> => {
	const adminToken: string | null = localStorage.getItem("AdminToken");
	const apiGatewayOptions: ApiGatewayOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"X-Api-Key": adminToken,
		},
	};

	const response: AxiosResponse = await axios.post(
		`${API_GATEWAY_ENDPOINT}/${endpoint}`,
		params,
		apiGatewayOptions
	);

	return response;
};
