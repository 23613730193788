import DashboardLayout from "../components/layout/DashboardLayout";

const Dashboard = () => {
	return (
		<DashboardLayout>
			<div>Dashboard to follow</div>
		</DashboardLayout>
	);
};

export default Dashboard;
