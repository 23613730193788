import { Spin } from "antd";

const PageLoader = () => {
	return (
		<div className="flex h-[80vh] w-full flex-col items-center justify-center">
			<Spin size="large" />
		</div>
	);
};

export default PageLoader;
