import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography } from "antd";
import { AuthProviderContext } from "../providers/AuthProvider";
import { useContext, useState } from "react";
import { loginAdmin } from "../queries/auth";
import { showErrorToast } from "../components/toasts/ToastError";
import { trimErrorMessage } from "../utils/trimeErrorMessage";
import { ToastContainer } from "react-toastify";

const layout = {
	labelCol: { span: 0 },
	wrapperCol: { span: 24 },
};

const { Text } = Typography;

const Signin = () => {
	const [isSigningIn, setIsSigningIn] = useState(false);
	const navigate = useNavigate();
	const { authDispatch } = useContext(AuthProviderContext);

	const onFinish = async (values: any) => {
		setIsSigningIn(true);
		try {
			const loginResponse = await loginAdmin(values);

			if (loginResponse.data.loginAdmin.id) {
				const { token, ...adminDataWithoutToken } =
					loginResponse.data.loginAdmin;
				localStorage.setItem("AdminToken", token);
				authDispatch({ type: "SIGN_IN", payload: adminDataWithoutToken });
				navigate("/");
			}
		} catch (error: any) {
			showErrorToast({
				message: trimErrorMessage(error.response.data.message),
			});
		}
		setIsSigningIn(false);
	};

	return (
		<div className="flex flex-col justify-center items-center h-screen">
			<Text className="text-4xl font-bold text-[#032EB7] uppercase">
				Talentboost PH
			</Text>
			<Text className="text-2xl font-bold text-[#032EB7] mb-4">
				Admin Console
			</Text>
			<Text
				type="secondary"
				className="mb-8">
				Sign in to continue
			</Text>
			<Form
				{...layout}
				style={{ width: "345px" }}
				name="login-form"
				className="justify-center flex flex-col"
				onFinish={onFinish}>
				<Form.Item
					name="email"
					rules={[
						{ required: true, message: "Email address is required." },
						{ type: "email", message: "Please enter a valid email address." },
					]}>
					<Input
						size="large"
						placeholder="Email"
						className="input-centered-round"
					/>
				</Form.Item>

				<Form.Item
					name="password"
					rules={[{ required: true, message: "Please input password" }]}>
					<Input.Password
						size="large"
						placeholder="Password"
						className="input-centered-round"
					/>
				</Form.Item>

				<div className="flex flex-col w-full items-center justify-center">
					<Button
						size="large"
						htmlType="submit"
						loading={isSigningIn}
						className="w-full bg-[#032EB7] text-white">
						{isSigningIn ? "Signing in..." : "Sign in"}
					</Button>
				</div>
			</Form>
			<ToastContainer />
		</div>
	);
};
export default Signin;
