import { useContext, useEffect, useState } from "react";
import {
	createNewCourseForAllUsers,
	deleteCourseForAllUsers,
	getPathwaysByOrgStatus,
} from "../../queries/pathways";
import { OrgProviderContext } from "../../providers/OrgProvider";
import { useNavigate, useParams } from "react-router-dom";
import { Course } from "./Pathways";
import { Row, Modal, Spin } from "antd";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { PathwayProviderContext } from "../../providers/PathwayProvider";
import { LoadingOutlined } from "@ant-design/icons";

const Courses = () => {
	const { orgState } = useContext(OrgProviderContext);
	const { pathwayState, pathwayDispatch } = useContext(PathwayProviderContext);
	const { activePathway } = pathwayState;
	const { slug } = useParams();
	const [createModalOpen, setIsCreateModalOpen] = useState(false);
	const [deleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [confirmDeleteText, setConfirmDeleteText] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const [newCourse, setNewCourse] = useState<Course>({
		orgId: "",
		pathwayId: "",
		id: "",
		title: "",
		description: "",
		coverPhotoUrl: "",
		deps: [],
	});
	const [idToBeDeleted, setIdToBeDeleted] = useState("");

	const courseToBeDeleted = activePathway?.courses?.filter(
		(item) => item.id === idToBeDeleted
	)[0];

	const [isFormValid, setIsFormValid] = useState(false);

	useEffect(() => {
		// Check if all required fields are filled
		const allFieldsFilled: any =
			newCourse.title &&
			newCourse.id &&
			newCourse.description &&
			newCourse.coverPhotoUrl;

		setIsFormValid(allFieldsFilled);
	}, [newCourse]);

	const navigate = useNavigate();

	const getActivePathway = async () => {
		const _pathway = await getPathwaysByOrgStatus(orgState.currentOrg, slug);
		pathwayDispatch({
			type: "SET_ACTIVE_PATHWAY",
			payload: _pathway[0],
		});
	};

	useEffect(() => {
		if (!activePathway) {
			getActivePathway();
		}

		window.scrollTo(0, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const CourseRow = (item: Course, index: number) => {
		const styles = "p-2 pb-6";

		return (
			<tr
				key={index}
				className="border-b-2 align-top">
				<td className={styles}>{item.description}</td>
				<td className={styles}>{item.title}</td>
				<td className={styles}>{item.id}</td>
				<td className={styles}>
					<div className="flex flex-row items-start justify-between">
						{item.deps.length === 0 ? (
							"--"
						) : (
							<div>
								{item.deps.map((cid, index) => (
									<div key={index}>{displayTitle(cid)}</div>
								))}
							</div>
						)}
					</div>
				</td>
				<td className="flex items-center justify-center pt-2 gap-2">
					<button
						className="pt-1"
						onClick={() => {
							navigate(
								`/pathways/manage-courses/${activePathway?.id}/course/${item.id}?orgId=${orgState.currentOrg}`
							);
						}}>
						<FaEdit className="text-lg" />
					</button>
					<button
						onClick={() => {
							setIdToBeDeleted(item.id);
							setIsDeleteModalOpen(true);
						}}>
						<MdDeleteForever className="text-red-600 text-2xl" />
					</button>
				</td>
			</tr>
		);
	};

	const displayTitle = (cid: string) => {
		const current = activePathway?.courses?.find((item) => item.id === cid);
		return current ? current.title : "";
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setNewCourse((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleDepsChange = (
		e: React.ChangeEvent<HTMLSelectElement>,
		index: number
	) => {
		const { value } = e.target;
		const newDeps = [...newCourse.deps];
		newDeps[index] = value;
		setNewCourse((prevValues) => ({
			...prevValues,
			deps: newDeps,
		}));
	};

	const addDep = () => {
		setNewCourse((prevValues) => ({
			...prevValues,
			deps: [...prevValues.deps, ""],
		}));
	};

	const removeDep = (index: number) => {
		const newDeps = newCourse.deps.filter((_, i) => i !== index);
		setNewCourse((prevValues) => ({
			...prevValues,
			deps: newDeps,
		}));
	};

	const closeCreateModal = () => {
		setNewCourse((prev) => ({
			...prev,
			orgId: "",
			pathwayId: "",
			id: "",
			title: "",
			description: "",
			coverPhotoUrl: "",
			deps: [],
		}));
		setIsCreateModalOpen(false);
	};

	const closeDeleteModal = () => {
		setConfirmDeleteText("");
		setIdToBeDeleted("");
		setIsDeleteModalOpen(false);
	};

	const handleCreate = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsLoading(true);

		newCourse.orgId = activePathway?.orgId;
		newCourse.pathwayId = activePathway?.id;
		const modifiedDeps = newCourse.deps.filter((dep) => dep !== "");

		try {
			await createNewCourseForAllUsers(
				newCourse.orgId!,
				newCourse.pathwayId!,
				newCourse.id,
				newCourse.title,
				newCourse.description,
				newCourse.coverPhotoUrl,
				modifiedDeps
			);

			getActivePathway();
			closeCreateModal();
		} catch (error) {
			console.error("Error deleting course:", error);
		}

		setIsLoading(false);
	};

	const handleDelete = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsLoading(true);
		const orgId = activePathway?.orgId;
		const pathwayId = activePathway?.id;

		try {
			await deleteCourseForAllUsers(orgId!, pathwayId!, idToBeDeleted);
			getActivePathway();
			closeDeleteModal();
		} catch (error) {
			console.error("Error deleting course:", error);
		}
		setIsLoading(false);
	};

	return (
		<div>
			<div className="flex justify-between">
				<h1 className="text-2xl mb-1 font-bold">
					{activePathway?.title} Courses
				</h1>
				<button
					className="bg-primary text-white px-4 py-2 rounded"
					onClick={() => {
						setIsCreateModalOpen(true);
					}}>
					Add New Course
				</button>
			</div>

			<Row className="w-full mt-2">
				<table className="w-full">
					<thead className="bg-primary text-white">
						<tr className="text-left">
							<th className="min-w-[120px] p-2">Course Code</th>
							<th className="min-w-[200px] p-2">Title</th>
							<th className="min-w-[200px] p-2">ID</th>
							<th className="min-w-[80px]">Dependencies</th>
							<th className="min-w-[100px] p-2 text-center">Actions</th>
						</tr>
					</thead>
					<tbody>
						{activePathway?.courses
							?.slice() // Create a shallow copy of the array to avoid mutating the original array
							.sort((a, b) => a.description.localeCompare(b.description)) // Sort alphabetically by description
							.map((item, index) => {
								return CourseRow(item, index);
							})}
					</tbody>
				</table>
			</Row>

			{/* CREATE NEW COURSE MODAL */}
			<Modal
				width={"70vw"}
				centered
				open={createModalOpen}
				onOk={handleCreate}
				onCancel={() => closeCreateModal()}
				okButtonProps={{ disabled: !isFormValid }}
				okText={
					isLoading ? (
						<>
							Creating Course...
							<Spin
								indicator={<LoadingOutlined spin />}
								size="small"
								style={{ color: "white", marginLeft: "8px" }}
							/>
						</>
					) : (
						<>Create Course</>
					)
				}
				cancelText="Cancel">
				<div className="space-y-4 p-2">
					<h1 className="text-2xl font-bold mb-4">
						Create New Course for {activePathway?.title}
					</h1>
					<form
						onSubmit={handleCreate}
						className="space-y-4">
						<div className="flex flex-col">
							<label className="mb-2 font-medium">Title:</label>
							<input
								type="text"
								name="title"
								value={newCourse.title}
								onChange={handleChange}
								className="border border-gray-300 rounded p-2"
								required
							/>
						</div>
						<div className="flex flex-col">
							<label className="mb-2 font-medium">ID:</label>
							<input
								type="text"
								name="id"
								value={newCourse.id}
								onChange={handleChange}
								className="border border-gray-300 rounded p-2"
								required
							/>
						</div>
						<div className="flex flex-col">
							<label className="mb-2 font-medium">Course Code:</label>
							<input
								type="text"
								name="description"
								value={newCourse.description}
								onChange={handleChange}
								className="border border-gray-300 rounded p-2"
								required
							/>
						</div>
						<div className="flex flex-col">
							<label className="mb-2 font-medium">Dependencies:</label>
							{newCourse.deps.map((dep, index) => (
								<div
									key={index}
									className="flex items-center mb-2">
									<select
										value={dep}
										onChange={(e) => handleDepsChange(e, index)}
										className="border border-gray-300 rounded p-2 flex-grow"
										required>
										<option
											value=""
											disabled>
											Select a dependency
										</option>
										{activePathway?.courses?.map((course) => (
											<option
												key={course.id}
												value={course.id}>
												{`${course.description}: ${course.title}`}
											</option>
										))}
									</select>
									<button
										type="button"
										onClick={() => removeDep(index)}
										className="ml-2 bg-red-500 text-white px-4 py-2 rounded">
										Remove
									</button>
								</div>
							))}
							<button
								type="button"
								onClick={addDep}
								className="bg-blue-500 text-white px-4 py-2 rounded">
								Add Dependency
							</button>
						</div>
						<div className="flex flex-col">
							<label className="mb-2 font-medium">Cover Photo URL:</label>
							<input
								type="text"
								name="coverPhotoUrl"
								value={newCourse.coverPhotoUrl}
								onChange={handleChange}
								className="border border-gray-300 rounded p-2"
								required
							/>
							<h4 className="mt-4 font-medium">Cover Photo Preview:</h4>
							{newCourse.coverPhotoUrl && (
								<img
									src={newCourse.coverPhotoUrl}
									alt="Cover Photo"
									className="mt-2  rounded shadow-md w-[200px]"
								/>
							)}
						</div>
					</form>
				</div>
			</Modal>

			{/* DELETE COURSE MODAL */}
			<Modal
				width={"50vw"}
				centered
				open={deleteModalOpen}
				onOk={handleDelete}
				onCancel={() => closeDeleteModal()}
				okText={
					isLoading ? (
						<>
							Removing Course...
							<Spin
								indicator={<LoadingOutlined spin />}
								size="small"
								style={{ color: "white", marginLeft: "8px" }}
							/>
						</>
					) : (
						<>Remove Course</>
					)
				}
				okButtonProps={{
					disabled: confirmDeleteText.toLowerCase() !== "remove",
				}}
				cancelText="Cancel">
				<div className="space-y-4 p-2">
					<h1 className="text-2xl font-bold mb-4">
						Are you sure you want to remove {courseToBeDeleted?.title} from{" "}
						{activePathway?.title}?
					</h1>
					<p>Type "remove" in the text field below to confirm</p>
					<input
						type="text"
						value={confirmDeleteText}
						onChange={(e) => setConfirmDeleteText(e.target.value)}
						className="border border-gray-300 rounded p-2 w-full"
						placeholder="remove"
					/>
				</div>
			</Modal>
		</div>
	);
};

export default Courses;
