import { fetchAdminApi } from "./api/adminApi";

export const getAllAdmins = async () => {
	const getAllAdminsQuery = `
    query getAllAdmins {
        getAllAdmins {
            id
            name
            email
            createdAt
            verifiedAt
        }
      }`;

	const params = {
		query: getAllAdminsQuery,
	};

	const response = await fetchAdminApi(params);

	const admins = response.data.getAllAdmins;
	return admins;
};

export const createAdmin = async (email: string, name: string) => {
	const createAdminMutation = `
    mutation createAdmin($input: CreateAdminInput!) {
        createAdmin(input: $input) {
            id
            name
            email
            createdAt
            updatedAt
            verifiedAt
        }
    }`;

	const input = {
		email: email,
		name: name,
	};

	const params = {
		query: createAdminMutation,
		variables: { input },
	};

	const response = await fetchAdminApi(params);

	const admins = response.data.createAdmin;
	return admins;
};

export const getCurrentAdmin = async (adminToken: string) => {
	const getCurrentAdminQuery = `
    query getCurrentAdmin($input: GetCurrentAdminInput!) {
		getCurrentAdmin(input: $input) {
			id
			name
			email
		}
	  }`;

	const input = {
		adminToken: adminToken,
	};

	const params = {
		query: getCurrentAdminQuery,
		variables: { input },
	};

	const response = await fetchAdminApi(params);

	const admins = response.data.getCurrentAdmin;
	return admins;
};

export const deleteAdmin = async (email: string, id: string) => {
	const deleteAdminMutation = `
    mutation deleteAdmin($input: DeleteAdminInput!) {
		deleteAdmin(input: $input) {
			id
			email
		}
	}`;

	const input = {
		email: email,
		id: id,
	};

	const params = {
		query: deleteAdminMutation,
		variables: { input },
	};

	const response = await fetchAdminApi(params);

	const deletedAdmin = response.data.deleteAdmin;
	return deletedAdmin;
};
