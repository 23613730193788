import { fetchAdminApi } from "./api/adminApi";

export const getAllLearners = async (currentOrg: string) => {
	const getAllLearnersQuery = `
    query getAllLearners($input: GetAllLearnersInput!) {
        getAllLearners(input: $input) {
            id
            firstName
            lastName
            email
            orgId
            enrolledPathways
            createdAt
            verifiedAt
        }
      }`;

	const input = {
		orgId: currentOrg,
	};

	const params = {
		query: getAllLearnersQuery,
		variables: { input },
	};

	const response = await fetchAdminApi(params);

	const learners = response.data.getAllLearners;
	return learners;
};

export const updateLearnerPathways = async (
	currentOrg: string,
	email: string,
	enrolledPathways: string[]
) => {
	const updateLearnerPattwaysMutation = `
    mutation updateLearnerPathways($input: UpdateLearnerByIdInput!) {
        updateLearnerPathways(input: $input) {
            id
            firstName
            lastName
            email
            orgId
            enrolledPathways
        }
      }`;

	const input = {
		orgId: currentOrg,
		email: email,
		enrolledPathways: enrolledPathways,
	};

	const params = {
		query: updateLearnerPattwaysMutation,
		variables: { input },
	};

	const response = await fetchAdminApi(params);

	const updatedLearner = response.data.updateLearnerPathways;
	return updatedLearner;
};
