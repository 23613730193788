import { fetchAdminApi } from "./api/adminApi";

export const getPathwaysByOrgStatus = async (orgId: string, slug?: string) => {
	const getPathwaysByOrgStatusQuery = `
    query getPathwaysByOrgStatus($input: GetPathwaysByOrgStatusInput!) {
    getPathwaysByOrgStatus(input: $input) {
        id
        orgId
        status
        title
        description
        createdAt
        updatedAt
        courses {
            id
            title
            description
            percent
            deps
            coverPhotoUrl
            }
		schedules {
			id
			schedules {
				id
				schedule
				sessionItems {
					sessionItemId
					schedule
				}
			}
		}
        }
    }
    `;

	const input = {
		orgId: orgId,
		pathwayId: slug,
	};

	const params = {
		query: getPathwaysByOrgStatusQuery,
		variables: { input },
	};

	const response = await fetchAdminApi(params);
	return response?.data?.getPathwaysByOrgStatus;
};

export const createNewCourseForAllUsers = async (
	orgId: string,
	pathwayId: String,
	id: string,
	title: string,
	description: string,
	coverPhotoUrl: string,
	deps: string[]
) => {
	const creatNewCourseForAllUsersMutation = `
    mutation createNewCourseForAllUsers($input: CreateNewCoursePerUserInput!) {
            createNewCourseForAllUsers(input: $input) {
                id
                title
                description
                deps
                coverPhotoUrl
            }
        }
    `;

	const input = {
		orgId: orgId,
		pathwayId: pathwayId,
		id: id,
		title: title,
		description: description,
		coverPhotoUrl: coverPhotoUrl,
		deps: deps,
	};

	const params = {
		query: creatNewCourseForAllUsersMutation,
		variables: { input },
	};

	const response = await fetchAdminApi(params);
	return response?.data?.createNewCourseForAllUsers;
};

export const deleteCourseForAllUsers = async (
	orgId: string,
	pathwayId: String,
	id: string
) => {
	const deleteCourseForAllUsersMutation = `
    mutation deleteCourseForAllUsers($input: DeleteCourseForAllUsersInput!) {
            deleteCourseForAllUsers(input: $input) {
                id
                title
                description
                deps
                coverPhotoUrl
            }
        }
    `;

	const input = {
		orgId: orgId,
		pathwayId: pathwayId,
		id: id,
	};

	const params = {
		query: deleteCourseForAllUsersMutation,
		variables: { input },
	};

	const response = await fetchAdminApi(params);
	return response?.data?.deleteCourseForAllUsers;
};

interface SessionItemSchedule {
	sessionItemId: string;
	schedule: string;
}

export interface SessionSchedule {
	id: string;
	schedule: string;
	sessionItems: SessionItemSchedule[];
}

export const modifyCourseDetailsByPathway = async (
	schedule?: SessionSchedule[],
	orgId?: string,
	pathwayId?: String,
	id?: string,
	newTitle?: string,
	newDescription?: string,
	newCoverPhotoUrl?: string,
	newDeps?: string[]
) => {
	const modifyCourseDetailsByPathwayMutation = `
    mutation modifyCourseDetailsByPathway($input: ModifyCourseDetailsByPathwayInput!) {
            modifyCourseDetailsByPathway(input: $input) {
                id
                title
                description
                deps
                coverPhotoUrl
            }
        }
    `;

	const input = {
		schedule: schedule,
		orgId: orgId,
		pathwayId: pathwayId,
		id: id,
		newTitle: newTitle,
		newDescription: newDescription,
		newCoverPhotoUrl: newCoverPhotoUrl,
		newDeps: newDeps,
	};

	const params = {
		query: modifyCourseDetailsByPathwayMutation,
		variables: { input },
	};

	const response = await fetchAdminApi(params);
	return response?.data?.modifyCourseDetailsByPathway;
};

export const duplicatePathway = async (
	orgId: string,
	existingId: string,
	newTitle: string,
	newDescription: string
) => {
	const duplicatePathwayMutation = `
	mutation duplicatePathway($input: DuplicatePathwayInput!) {
				duplicatePathway(input: $input) {
					id
					orgId
					title
					description
				}
			}
    `;

	const input = {
		orgId: orgId,
		existingId: existingId,
		newTitle: newTitle,
		newDescription: newDescription,
	};

	const params = {
		query: duplicatePathwayMutation,
		variables: { input },
	};

	const response = await fetchAdminApi(params);
	return response?.data?.deleteCourseForAllUsers;
};
