import { createContext, useReducer, ReactNode, useEffect } from "react";
import { getCurrentAdmin } from "../queries/admins";

interface State {
	isAuthenticated: boolean;
	currentUser: string | null;
	loading: boolean;
}

interface Action {
	type: string;
	payload?: any; // Define the payload type according to your action types
}

const initialState: State = {
	isAuthenticated: false,
	currentUser: null,
	loading: false,
};
export const AuthProviderContext = createContext<{
	authState: State;
	authDispatch: React.Dispatch<Action>;
}>({ authState: initialState, authDispatch: () => null });

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "SIGN_IN":
			return {
				...state,
				isAuthenticated: true,
				currentUser: action.payload,
				loading: false,
			};
		case "SIGN_OUT":
			return {
				...state,
				isAuthenticated: false,
				currentUser: null,
				loading: false,
			};
		case "SET_LOADING":
			return {
				...state,
				loading: true,
			};
		default:
			return state;
	}
};

interface AuthProviderProps {
	children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const adminToken = localStorage.getItem("AdminToken") || "";
	const getCurrentAuthenticatedAdmin = async () => {
		try {
			const user = await getCurrentAdmin(adminToken);

			if (user) {
				dispatch({ type: "SIGN_IN", payload: user });
			} else {
				dispatch({ type: "SIGN_OUT" });
			}
		} catch (error) {
			console.error("Error fetching authenticated admin:", error);
			dispatch({ type: "SIGN_OUT" });
			localStorage.removeItem("AdminToken");
		}
	};

	useEffect(() => {
		if (adminToken) {
			getCurrentAuthenticatedAdmin();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthProviderContext.Provider
			value={{ authState: state, authDispatch: dispatch }}>
			{children}
		</AuthProviderContext.Provider>
	);
};
